<template>
  <div class="dialog">
    <slot name="dialog">
      <div :class="['dialog-content', setClass]" :style="style">
        <slot name="content" class="dialog-content">
          <img
            class="close"
            @click="handleSubmit"
            src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/close.png"
          />
          <div class="msg">{{ msg }}</div>
          <slot name="btn">
            <img
              class="submit"
              @click="handleSubmit"
              src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/dialogBtn.png"
            />
          </slot>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "taoDialog",
  props: {
    msg: {
      type: String,
      default: "",
    },
    backgroundImage: {
      type: String,
      default:
        "https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/dialogMsg.png",
    },
    height: {
      type: Number,
      default: 514,
    },
    width: {
      type: Number,
      default: 557,
    },
    setClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    style() {
      return {
        // width: `${this.width / 2}px`,
        // height: `${this.height / 2}px`,
        background: `url(${this.backgroundImage}) top center/contain no-repeat`,
      };
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("close");
    },
    
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 1, 0, 0.6);
  z-index: 2;

  &-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%);
    width: 557px;
    height: 514px;

    .msg {
      font-size: 30px;
      color: #953d13;
      text-align: center;
      clear: both;
      height: 150px;
      padding: 40px 30px 0;
      font-weight: normal;
      box-sizing: border-box;
    }

    .submit {
      display: block;
      margin: 0 auto;
      width: 449px;
      height: 104px;
    }

    .close {
      margin: 182px 20px 0 0;
      width: 40px;
      float: right;
    }
  }
}
</style>
