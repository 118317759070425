<template>
  <div class="login">
    <form class="login-content" @submit.prevent="handleSubmit">
      <div class="phone">
        <van-field
          v-model="phone"
          type="tel"
          maxlength="11"
          placeholder="请输入联系电话"
        />
      </div>
      <div class="code">
        <van-field
          v-model="code"
          type="number"
          center
          maxlength="6"
          autocomplete="one-time-code"
          placeholder="请输入验证码"
          class="input-code"
        >
          <template slot="right-icon">
            <div class="code-text" @click="getPhonecode">
              {{ codeBtn.codeText }}
            </div>
          </template>
        </van-field>
      </div>
      <img
        class="submit"
        @click="handleSubmit"
        src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/loginBTN.png"
      />
    </form>
    <tao-dialog v-show="dialog.visibility" :msg="dialog.msg" @close="close" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getCode, login } from "@/api/tao.js";
import taoDialog from "./dialog/popup.vue";
export default {
  components: {
    taoDialog,
  },
  data() {
    return {
      phone: "",
      code: "",
      codeBtn: {
        codeTime: 60,
        codeText: "获取验证码",
        codeStatus: true,
      },
      rules: {
        phone: {
          rule: /^1[3456789]\d{9}$/,
          msg: "您输入的手机号有误，请重新输入！",
        },
        code: {
          rule: /^[0-9]{6}$/,
          msg: "请输入6位验证码",
        },
      },
      dialog: {
        visibility: false,
        msg: "",
      },
      type2: "",
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    document.title = "随心淘";
  },
  methods: {
    ...mapMutations(["sxt/SET_DATA"]),
    //判断验证是否符合要求，合法性校验
    validate(key) {
      let bool = true;
      if (!this.rules[key].rule.test(this[key])) {
        this.dialog = {
          visibility: true,
          msg: this.rules[key].msg,
        };
        //取反
        bool = false;
        return false;
      }
      return bool;
    },
    //手机验证码登录
    async handleSubmit() {
      if (!this.validate("phone")) return;
      if (!this.validate("code")) return;
      const openID = this.$route.query.wechat_open_id;
      const res = await login({
        phone_number: this.phone,
        phone_code: this.code,
        wechat_open_id: openID,
      });
      if (res.code == 0) {
        this["sxt/SET_DATA"]({
          phone: this.phone,
          token: res.token,
        });
        this.$router.replace("/tao/equityCenter/homePage");
      } else {
        this.$toast({
          duration: 8000,
          message: res.message,
        });
      }
    },
    //获取验证码按钮点击计时事件
    async getPhonecode() {
      if (this.validate("phone") && this.codeBtn.codeStatus) {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        const res = await getCode({
          phone_number: this.phone,
        });

        if (res.code === 0) {
          this.codeBtn.codeStatus = false;
          let timerId = setInterval(() => {
            let codetime = this.codeBtn.codeTime;
            codetime--;
            this.codeBtn.codeTime = codetime;
            this.codeBtn.codeText = codetime + "s";
            if (codetime < 1) {
              clearInterval(timerId);
              this.codeBtn.codeText = "重新获取";
              this.codeBtn.codeTime = 60;
              this.codeBtn.codeStatus = true;
            }
          }, 1000);
        } else {
          this.$toast({
            duration: 8000,
            message: res.message,
          });
        }
        this.$toast.clear();
      }
    },
    close() {
      this.dialog = {
        visibility: false,
        msg: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  min-height: 100%;
  background-color: #ff475d;

  &-content {
    display: block;
    height: 1199px;
    background: url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/loginBG.png")
      center top/contain no-repeat;

    .phone {
      padding-top: 515px;
    }
    ::v-deep .van-cell {
      width: 629px;
      background-color: #ffffff;
      border-radius: 12px;
      border: solid 1px #ffd6ce;
      margin: 0 auto 26px;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 24px;
      height: 88px;
      line-height: 88px;

      &.input-code {
        .code-text {
          width: 207px;
          text-align: center;
          color: #ffffff;
          font-size: 30px;
          font-weight: normal;
          background-image: linear-gradient(
              140deg,
              #ff9753 0%,
              #ff774a 62%,
              #ff5641 95%
            ),
            linear-gradient(#ff9753, #ff9753);
          background-blend-mode: normal, normal;
        }
      }

      &::after {
        border: none;
      }

      .van-field__control {
        color: rgb(48, 49, 51);
        font-weight: normal;

        &::-webkit-input-placeholder {
          font-size: 28px;
          font-weight: normal;
          color: rgb(175, 62, 81, 0.5);
        }
      }
    }

    .submit {
      display: block;
      margin: 203px auto 0;
      width: 629px;
    }
  }
}
</style>
